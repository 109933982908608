// Library imports
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import * as queryString from 'query-string';

// Data imports
const {
  lastSuportedReleases,
  cloudRelease,
  lastRelease,
  lastCloudReleaseNotes,
  lastOnPremReleaseNotes,
} = require('../config/site');
const dataSourceMapping = require('../data/DataWizardMap/dataSourceMapping');
const localizationList = require('../data/DataWizardMap/localizationList');

// Use Regex to identify the relevant incorta version number for docs
// A comment
function cleanVersionString(incortaVersion) {
  const versionRegex = /[0-9][.][0-9]\d?/;
  const validArray = versionRegex.exec(incortaVersion);

  return validArray[0];
}

// Check if input version is valid, if not return cloud release
function findVersion(incortaVersion) {
  if (!incortaVersion) {
    return cloudRelease;
  }

  if (incortaVersion === 'latest' || incortaVersion === 'current-onprem') {
    return lastRelease;
  }
  if (!lastSuportedReleases.includes(incortaVersion)) {
    return cloudRelease;
  }

  const versionString = cleanVersionString(incortaVersion);

  return lastSuportedReleases.includes(versionString)
    ? versionString
    : cloudRelease;
}

// Navigates when only using slug and version parameters
function findSlug(slug, parsedVersion, hash) {
  const docsFourVersions = [
    '5.0',
    '4.9',
    '4.8',
    '4.7',
    '4.6',
    '4.5',
    '4.4',
    '4.3',
  ];
  let version = findVersion(parsedVersion);
  let identifiedSlug = slug;

  // Direct to the latest Cloud Release Notes
  if (slug === 'latest-cloud-release-notes') {
    version = 'latest';
    identifiedSlug = lastCloudReleaseNotes;
  }

  // Direct to the latest On-Prem Release Notes
  if (slug === 'latest-onprem-release-notes') {
    version = lastRelease;
    identifiedSlug = lastOnPremReleaseNotes;
  }

  // If the query contains a header hash, append to navigation string.
  if (hash !== null) {
    identifiedSlug += hash;
  }

  if (docsFourVersions.includes(version)) {
    navigate(`https://docs4.incorta.com/${version}/${identifiedSlug}`, {
      replace: true,
    });
  }

  navigate(`/${version}/${identifiedSlug}`, { replace: true });
}

// Check mapping object for a valid iframe connector
// Return the connector slug if found
function findiframeConnector(type, subtype, version) {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of dataSourceMapping) {
    if (
      item.type === type &&
      item.subtype === subtype &&
      item.version === version
    ) {
      return [item.slug, item.locale];
    }
  }
  return [null, null];
}

// Use Regex to identify the locale language
function cleanLocaleString(locale) {
  const localeRegex = /^[a-zA-Z]{2}/;
  const validArray = localeRegex.exec(locale);

  return validArray[0];
}

// Check for valid language id & localization version exist, return the language id or default to en
function findLanguage(parsedLocale, mappedLocale) {
  const localeString = cleanLocaleString(parsedLocale);

  if (
    localizationList.locale.includes(localeString) &&
    mappedLocale.includes(localeString)
  ) {
    return localeString;
  }
  return 'en';
}

/**
 * Search: This function allows navigation with just the slug entry to the desired documentation.
 *  Optionally the version number can be used as a parameter. Defaults to the cloud version
 * @param {*} props
 * @returns
 */
function Search({ location }) {
  useEffect(() => {
    // Sentinal statement when no parameters exist
    if (location.search === '') {
      navigate('/');
    }

    const parsed = queryString.parse(location.search);
    const { hash } = location;

    // Check if the parameters exist for a data wizard connector search
    if (parsed.type && parsed.subtype && parsed.version) {
      const [mappedSlug, mappedLocale] = findiframeConnector(
        parsed.type,
        parsed.subtype,
        parsed.version,
      );

      if (mappedSlug === '' || mappedSlug === null) {
        return;
      }

      if (mappedLocale === '' || mappedLocale === null) {
        return;
      }

      const locale = findLanguage(parsed.locale, mappedLocale);

      const incortaVersion = cloudRelease;

      // Navigate to normal doc if data wizard doc doesn't exist
      if (!mappedSlug.includes('iframe')) {
        navigate(`/${incortaVersion}/${mappedSlug}`);
      } else if (locale === 'en') {
        navigate(`/${incortaVersion}/${mappedSlug}`);
      } else {
        navigate(`/${incortaVersion}/${mappedSlug}`);
      }
    }

    // Check if the two parameters exist for a doc slug search
    if (parsed.slug && !parsed.subtype) {
      findSlug(parsed.slug, parsed.version, hash);
    }
  }, [location, location.search, location.hash]);

  return <></>;
}

export default Search;
