module.exports = {
  cloudRelease: 'latest',
  lastCloudReleaseNotes: 'release-notes-2024-7-0',
  lastOnPremReleaseNotes: 'release-notes-2024-7-0',
  homeVersion: 'docs',
  lastRelease: 'latest',
  allDocs: '/home/all-docs',
  docsFourUrl: 'https://docs4.incorta.com',
  lastSuportedReleases: [
    'latest',
    'cloud',
    '6.0',
    '5.2',
    '5.1',
  ],

  /*
  lastSuportedReleases: [
    '6.0',
    '5.2',
    '5.1',
    '5.0',
    '4.9',
    '4.8',
    '4.7',
    '4.6',
    '4.5',
    '4.4',
    '4.3',
  ],

  */

  algoliaVersions: ['latest','cloud','6.0', '5.2', '5.1'],
  // nav links
  nav: [
    {
      text: 'Documentation',
      linkTo: '/',
      context: ['/'],
      hasLocale: true,
    },
/**
Removed to generalize the content
    {
      text: 'Cloud',
      linkTo: '/cloud/',
      context: ['cloud'],
      hasLocale: true,
    },
    {
      text: 'On-Premises',
      // Change with lastRelease change
      linkTo: '/6.0/',
      context: ['6.0'],
      hasLocale: true,
    },
*/
  ],
};
